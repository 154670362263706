import React from "react";
import { Trans } from "@lingui/macro";
import { Grid, TextField, MenuItem, Box, Stack, Typography } from "@mui/material";
import requestIMage1 from "../../images/headers/request-info.png";
import { countries } from "../../utils/countries";

function FormStep1({ formData, handleChange, error }) {
  const businessTypes = [
    { value: "bank", label: "Bank" },
    { value: "insurance", label: "Insurance" },
    { value: "asset_management", label: "Asset Management" },
    { value: "other", label: "Other" },
  ];

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={4} flexDirection={{ xs: "column-reverse", lg: "row" }}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Stack mb={4} spacing={0.5}>
            <Typography variant="h3">
              <Trans>
                Welcome to Allfunds Golden Data Hub
              </Trans>
            </Typography>
            <Typography>
              <Trans>
                Please fill out your personal info and start to build your own data-feed
              </Trans>
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <TextField
                select
                label="Select your business type"
                fullWidth
                variant="outlined"
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
              >
                {businessTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                label="Your Company"
                fullWidth
                required
                variant="outlined"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                select
                label="Country"
                fullWidth
                variant="outlined"
                name="country"
                value={formData.country}
                onChange={handleChange}
                SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 200,
                        },
                      },
                    },
                  }}
                >
                {countries().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
      ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                label="Your email"
                fullWidth
                required
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={error}
                helperText={error ? (<Trans>Invalid email address</Trans>) : null}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} width={{ xs: "100%", lg: "auto" }} display="flex" justifyContent="center">
          <Box width={{ xs: "100%", sm: "50%", md: "50%", lg: "100%" }}>
            <img src={requestIMage1} alt="request" style={{ width: "100%" }} />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormStep1;
