import React from "react";
import { Grid, Stack, Box, Typography, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft} from "@fortawesome/pro-light-svg-icons";
import { theme } from "../../themeV2";

function StepProgress({ activeStep, onStepClick }) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const steps = [
    {
      step: "Step 1",
      label: "Help us to know more about you",
      description: "Please fill out your personal details",
      active: activeStep === 1,
    },
    {
      step: "Step 2",
      label: "Select products of your interest",
      description: "Select the different products that you need in one entry point.",
      active: activeStep === 2,
    },
    {
      step: "Step 3",
      label: "Build your data feed",
      description: "Explore our catalog and define the data points of your interest.",
      active: activeStep === 3,
    },
    {
      step: "Step 4",
      label: "Define your product universe and other relevant inputs",
      description: "Help us to understand the scope of your need and send your request.",
      active: activeStep === 4,
    },
  ];

  const build_step = (step, isCompleted, isActive) => {
    return (
      <>
        <Stack spacing={1}>
          <Typography
            variant="h6"
            color={isActive ? "secondary" : isCompleted ? theme.palette.colors.grey.lighter : theme.palette.colors.blue.lighter}
          >
            {step.step}
          </Typography>
          <Typography variant="h5">
            {step.label}
          </Typography>
          <Typography fontSize={12} color={theme.palette.colors.blue.lightMedium}>
            {step.description}
          </Typography>
        </Stack>
        <Box
          border={3}
          borderColor={isActive ? theme.palette.secondary.main : isCompleted ? theme.palette.grey[400] : "grey.300"}
          borderRadius={5}
          my={1}
        />
      </>
    );
  };

  return (
    <Grid container spacing={2} mt={{ xs: 0, sm: 4 }}>
      { !isMobile ? steps.map((step, index) => {
        const isCompleted = index < activeStep - 1;
        const isActive = step.active;

        return (
          <Grid
            item
            xs={12}
            sm={3}
            key={index}
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
            onClick={() => onStepClick(index + 1)}
            style={{ cursor: "pointer" }}
          >
            {build_step(step, isCompleted, isActive)}
          </Grid>
        );
      })
      :
      (
        <Grid item xs={12}>
          <Stack
            pb={2}
            spacing={0.5}
            onClick={() => window.history.back()}
            style={{ cursor: "pointer" }}
            direction="row"
            alignItems="center"
            >
            <FontAwesomeIcon icon={faArrowLeft} />
            <Typography fontSize={14}>
              Back
            </Typography>
          </Stack>
          {build_step(steps.find((step) => step.active), false, true)}
        </Grid>
      ) }
    </Grid>
  );
}

export default StepProgress;
