import React from "react";
import { Grid, Button, Typography, Slider, Box } from "@mui/material";
import { theme } from "../../themeV2";

function FormStep4({ formData, handleChange }) {
  const dataQualityOptions = [
    { value: "delayed", label: "Delayed" },
    { value: "historical", label: "Historical data" },
    { value: "endOfDay", label: "End of day" },
    { value: "intraday", label: "Intraday" },
    { value: "realTime", label: "Real time" },
    { value: "overnight", label: "Overnight" },
  ];

  const marketOptions = [
    { value: "european", label: "European" },
    { value: "uk", label: "UK" },
    { value: "mena", label: "MENA" },
    { value: "asiaPacific", label: "Asia Pacific" },
    { value: "northAmerican", label: "North American" },
    { value: "southAmerican", label: "South American" },
  ];

  const handleSliderChange = (event, newValue) => {
    handleChange({ range: newValue });
  };

  const handleButtonClick = (name, option) => {
    handleChange({
      [name]: formData[name].some((item) => item.value === option.value)
        ? formData[name].filter((item) => item.value !== option.value)
        : [...formData[name], option],
    });
  };

  const buildOptions = (key, name, options) => {
    return (
      <Grid item xs={12} md={6}>
        <Box
          p={2}
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 1px 3px 0px rgba(16, 21, 46, 0.12)",
          }}>
          <Typography variant="heading">
            {name}
          </Typography>
          <Grid container spacing={{xs: 1, md: 2}} pt={4}>
            {options.map((option) => (
              <Grid item xs={6} sm={4} key={option.value}>
                <Button
                  fullWidth
                  onClick={() => handleButtonClick(key, option)}
                  sx={{
                    height: 48,
                    backgroundColor: theme.palette.colors.grey.dark,
                    fontWeight: 600,
                    fontSize: 16,
                    borderRadius: "10px",
                    color: theme.palette.colors.grey.darker,
                    ...(formData[key].some((item) => item.value === option.value) && {
                      backgroundColor: theme.palette.colors.red.dark,
                      color: "white",
                    }),
                    "&:hover": {
                      backgroundColor: theme.palette.colors.red.dark,
                      color: "white",
                    },
                  }}
                >
                  {option.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  };

  return (
    <div>
      <Box
        my={4}
        px={2}
        py={3}
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 1px 3px 0px rgba(16, 21, 46, 0.12)",
        }}
      >
        <Typography mb={3} fontSize={{ xs: 16, md: 18, lg: 18 }} fontWeight={600}>
          Select your range of products (if applicable)
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={1} md={0.3}>
            <Typography color={theme.palette.colors.blue.lighter} fontSize={{ xs: 14, md: 16, lg: 16 }}>0</Typography>
          </Grid>
          <Grid item xs={9} md={11}>
            <Slider
              value={formData.range}
              onChange={handleSliderChange}
              aria-labelledby="range-slider"
              min={0}
              max={10000}
              step={50}
              valueLabelDisplay="on"
              sx={{
                "& .MuiSlider-thumb": {
                  color: theme.palette.colors.red.dark,
                  height: 20,
                },
                "& .MuiSlider-track": {
                  color: theme.palette.colors.red.dark,
                  height: 10,
                },
                "& .MuiSlider-rail": {
                  color: theme.palette.colors.blue.lighter,
                },
                "& .MuiSlider-valueLabel": {
                  backgroundColor: theme.palette.colors.red.dark,
                  color: "#fff",
                },
              }}
            />
          </Grid>
          <Grid item xs={1.8} md={0.7} justifyContent="flex-end" display="flex">
            <Typography color={theme.palette.colors.blue.lighter} fontSize={{ xs: 14, md: 16, lg: 16 }}>10000</Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} pb={4}>
        {buildOptions("dataQuality", "Data quality", dataQualityOptions)}
        {buildOptions("markets", "Markets and Exchanges", marketOptions)}
      </Grid>
    </div>
  );
}

export default FormStep4;
